var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      _vm.titleEnText || _vm.titleJpText
        ? _c("h2", { staticClass: "dp-top-section__title" }, [
            _vm.titleEn
              ? _c("span", { staticClass: "dp-top-section__title-en" }, [
                  _vm._v(" " + _vm._s(_vm.titleEnText) + " "),
                  _vm.titleEn === "Favorite"
                    ? _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "ripple",
                              rawName: "v-ripple",
                              value: _vm.$utilities.getRippleOptions("light"),
                              expression:
                                "$utilities.getRippleOptions('light')",
                            },
                          ],
                          attrs: { type: "button" },
                          on: { click: _vm.onClickFavoriteHelp },
                        },
                        [
                          _c("Icon", {
                            attrs: {
                              name: "dpHelpCircleR",
                              color: "dpBlack01",
                              width: 21,
                              height: 21,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.titleJp
              ? _c("span", { staticClass: "dp-top-section__title-jp" }, [
                  _vm._v(" " + _vm._s(_vm.titleJpText) + " "),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._t("default"),
      _vm.contents && _vm.contents.length && _vm.linkTo
        ? _c(
            "DpTextButton",
            {
              attrs: { align: "right", href: _vm.linkTo, fluid: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "iconAppend",
                    fn: function () {
                      return [
                        _c("Icon", {
                          attrs: { name: "dpArrowRightB", color: "dpBlack01" },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3194498299
              ),
            },
            [_vm._v(" " + _vm._s(_vm.WORDS.DRIP_POD_TOP.PRO_RECIPE.MORE) + " ")]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }