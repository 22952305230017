<template>
  <div :class="classes">
    <h2 v-if="titleEnText || titleJpText" class="dp-top-section__title">
      <span v-if="titleEn" class="dp-top-section__title-en">
        {{ titleEnText }}
        <button
          v-if="titleEn === 'Favorite'"
          type="button"
          v-ripple="$utilities.getRippleOptions('light')"
          @click="onClickFavoriteHelp">
          <Icon
            name="dpHelpCircleR"
            color="dpBlack01"
            :width="21"
            :height="21" />
        </button>
      </span>
      <span v-if="titleJp" class="dp-top-section__title-jp">
        {{ titleJpText }}
      </span>
    </h2>

    <slot />

    <DpTextButton
      v-if="contents && contents.length && linkTo"
      align="right"
      :href="linkTo"
      fluid>
      <template v-slot:iconAppend>
        <Icon name="dpArrowRightB" color="dpBlack01" />
      </template>
      {{ WORDS.DRIP_POD_TOP.PRO_RECIPE.MORE }}
    </DpTextButton>
  </div>
</template>

<script>
import { WORDS } from '@/constants/words';
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  components: {
    DpTextButton: () => import('@/components/dripPod/core/TextButton.vue')
  },

  props: {
    contents: {
      type: Array,
      default: undefined
    },

    titleEn: {
      type: String,
      default: ''
    },

    titleJp: {
      type: String,
      default: ''
    },

    linkTo: {
      type: String,
      default: ''
    },

    isSecondary: {
      type: Boolean,
      default: false
    }
  },

  setup: (props, context) => {
    const titleEnText = computed(() => (props.titleEn ? props.titleEn : ''));
    const titleJpText = computed(() => (props.titleJp ? props.titleJp : ''));

    const classes = computed(() => [
      'dp-top-section',
      props.isSecondary ? 'dp-top-section--secondary' : ''
    ]);

    const onClickFavoriteHelp = () => {
      window.location.href =
        context.root.$customUrlScheme.dp('/favorite-overview');
    };

    return {
      WORDS,
      titleEnText,
      titleJpText,
      classes,
      onClickFavoriteHelp
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-top-section {
  padding: 32px 16px 36px;

  &:not(:last-of-type) {
    border-bottom: 1px solid variables.$dpGreyEa;
  }

  &--secondary {
    background-color: variables.$dpGreyF1;
    border-bottom: none;
  }

  &__title {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    margin-bottom: 24px;

    &-en {
      @include mixin.dpEnTitle;

      place-self: center left;
      font-size: 24px;
    }

    &-jp {
      place-self: center right;
      color: variables.$dpGrey44;
      font-weight: normal;
      font-size: 12px;
    }
  }
}
</style>
